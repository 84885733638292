import flatten, { unflatten } from 'flat'
import { difference, isArray, isEmpty, isNil, omit } from 'lodash'
import { nanoid } from 'nanoid'
import { apolloClient, APPLY_JOB_MUTATION, GET_JOB_DETAIL_QUERY, GET_MASTER_DATA } from '~/common/apollo'
import { EVENTS, PATHS } from '~/common/constants'
import { action, computed, event, observable, store } from '~/common/mobx.decorator'
import { appStore, authStore, masterStore, notifyStore } from '~/stores'
import { SEARCH_JOB_QUERY, SEARCH_SCROLL_END_JOB_QUERY } from './care-find-job.graphql'
import { SAVE_FILTER_QUERY } from '~/common/apollo/queries/job-filter.query'
import { CREATE_SAVE_MUTATION, SYNC_ANONYMOUS_WORKER_DATA, UPDATE_SAVE_MUTATION } from '~/common/apollo/mutations/jobs-filter.mutation'
import { careHomeStore } from '~/features/care-home/care-home.store'
import { convertStateToArray, convertStateToString, mappingFilterDate, mappingFilterJobSearch } from '~/common/helpers/mapping.helper'
import { logClickEvent, logDefaultActionEvent } from '~/common/tracking/event-client.tracking'
import { EVENT_CLICK_ID, EVENT_DEFAULT_ID, TYPE_JOB } from '~/common/tracking/event-click.constant'
import { lastViewedStore } from '~/features/last-viewed/last-viewed.store'
import { jobAlertsStore } from '~/components/job-alert-drawer/job-alert-drawer.store'
import { PAGE_LIMIT } from '~/common/constants/common.constant'

@store()
class CareFindJobStore {
	@observable searchData = null
	@observable sortKey = 'SCORE'

	@observable token = null
	@observable searchInfo
	@observable jobs = []
	@observable suggestedJobs = []
	@observable searchParams = {}
	@observable jobApplicantId = ''
	@observable shifts = {}
	@observable activeFilter = {}
	@observable filterOptions = null
	@observable listSaveSearch = []
	@observable isShowSaveSearchDialog = false
	@observable searchString
	@observable totalCount
	@observable lastSearch = {}
	@observable isActiveFilter = false
	@observable redirectSignUpLastJobId = null
	@observable filterValues = {}
	@observable activeFilterId = ''
	@observable searchSessionId = nanoid()

	@computed
	get searchStr() {
		return this.searchString
	}

	@computed
	get disciplineVal() {
		return []
	}

	@computed
	get specialtyOptionsVal() {
		return null
	}

	@computed
	get stateOptions() {
		return masterStore.licenseStates
	}

	@computed
	get hideDistance() {
		return !this.filterValues.locationCity
	}

	@computed
	get professionOptions() {
		return masterStore.profession
	}

	@computed
	get suggestedJobsItems() {
		return this.suggestedJobs
	}

	@computed
	get jobShiftOptions() {
		return masterStore.jobShifts
	}

	@computed
	get hasNextPage() {
		return this.jobs?.length < this.totalCount
	}

	@computed
	get specialtyOptions() {
		return masterStore.jobDisciplines
	}

	@computed
	get isSaveSearch() {
		return this.listSaveSearch?.every((item) => item?.isActive === false)
	}

	@computed
	get getActiveItem() {
		const activeValue = authStore.id
			? this.listSaveSearch?.find((item) => item.isActive === true)?.filterCondition
			: this.listSaveSearch?.find((item) => item.isActive === true)?.filterConditions

		const activeItem = convertStateToArray(activeValue)
		const anoActiveValue = this.listSaveSearch?.find((item) => item.isActive === true)?.filterConditions
		const anoActiveItem = convertStateToArray(anoActiveValue)
		const mapData = authStore.id ? activeItem : anoActiveItem
		return this.transferDataParams({ ...mapData, isActive: true })
	}

	@computed
	get showCustomFilter() {
		return (
			this.filterValues?.minWage ||
			this.filterValues?.maxWage ||
			this.filterValues?.minMatchingPercentage ||
			this.filterValues?.frequency ||
			this.filterValues?.shifts?.length > 0
		)
	}

	@computed
	get getActiveItemSaveFilter() {
		return !isEmpty(this.listSaveSearch) && this.listSaveSearch?.find((item) => item.isActive === true)
	}

	@action
	setActiveFilterId = (id) => {
		this.activeFilterId = id
	}

	@action
	setFilterValues = (values) => {
		this.filterValues = values
	}

	@action
	resetFilterValue = () => {
		this.filterValues = {}
	}

	@action
	resetSearchData = () => {
		this.searchData = null
	}

	@action
	setSearchStr = (searchInput) => {
		this.searchString = searchInput
	}

	@action
	setRedirectSignUpLastJobId = (job) => {
		this.redirectSignUpLastJobId = job
	}

	@action
	setInitSearchListList = () => {
		this.listSaveSearch = []
	}

	@action
	disciplineOptions(nameProfession) {
		let compresData = !!nameProfession
			? Object.values(masterStore?.jobDisciplines?.[nameProfession] || {})
			: Object.assign({}, ...Object.values(masterStore?.jobDisciplines || {}))

		const sortedData = Object.values(compresData).sort((a, b) => a.long_name.localeCompare(b.long_name))

		return sortedData.map((key) => ({
			label: key?.long_name,
			value: key.skill_name,
			id: key?.id,
		}))
	}

	@action
	transferDataParams = (data) => {
		return {
			profession: data?.profession,
			exclusiveJob: data?.exclusiveJob,
			maxWage: data?.maxWage,
			minWage: data?.minWage,
			minMatchingPercentage: data?.minMatchingPercentage,
			nearBy: data?.nearBy,
			shifts: data?.shifts,
			skillName: data?.skillName,
			radius: data?.radius,
			locationCities: data?.locationCities,
			locationStates: data?.locationStates,
			isActive: data?.isActive,
			jobTypes: data?.jobTypes,
			frequency: data?.frequency,
		}
	}

	@action
	setSearchParams = (newParams) => {
		this.searchParams = { ...this.searchParams, ...newParams }
	}

	@action
	resetSearchFilter = () => {
		this.listSaveSearch = this.listSaveSearch?.map((item) => ({ ...item, isActive: false }))
		this.filterValues = {}
		if (!authStore.id) {
			window.localStorage.setItem('saveSearchAno', JSON.stringify(this.listSaveSearch))
		}
	}

	@action
	fetchJobShift = async () => {
		const response = await apolloClient.query({
			query: GET_MASTER_DATA,
		})
		this.shifts =
			!isNil(response.data?.appInit?.masterData?.job?.shift) &&
			Object.values(response.data?.appInit?.masterData?.job?.shift).map((item) => {
				return {
					label: item,
					value: item,
				}
			})
	}

	@action
	setActiveFilterJob = (value) => {
		const transferData = {
			...value,
		}

		const activeSearchValue = transferData

		this.activeFilter = activeSearchValue
	}

	@action
	setIsActiveClick = (value) => {
		this.isActiveFilter = value
	}

	@action
	getFlattenFilter = (value) => {
		return omit(value, ['workerId', 'companyId', 'id', 'radius', 'isActive'])
	}

	@action
	onSubmitSearch = async (searchData, history) => {
		this.resetFilterValue()

		const previousSearchData = this.searchData && flatten(this.searchData)
		const newSearchData = flatten(searchData)

		const diff = difference(previousSearchData, newSearchData)

		if (!this.searchData || !isEmpty(diff)) {
			this.token = nanoid()
		}

		this.searchData = searchData

		this.setIsActiveClick(false)

		const transferData = mappingFilterJobSearch(this.searchData, false)

		const convertStringJob = convertStateToString(transferData)
		if (searchData?.externalId) {
			logClickEvent(EVENT_CLICK_ID.clickSearchJobsWithJobIdBtn, {
				job_id: searchData?.externalId,
			})
		} else {
			logClickEvent(EVENT_CLICK_ID.clickSearchJobsWithoutJobIdBtn, {
				...convertStringJob,
			})
		}

		this.setFilterValues(convertStringJob)
		history.push(PATHS.care.search)
	}

	@action
	refreshSearchJob = async (activeFilter, keyword) => {
		this.searchInfo = null
		this.jobs = []
		this.onScrollToEnd(activeFilter, keyword)
	}

	@action
	onCloseSearch = () => {
		this.searchData = null
		this.sortKey = 'SCORE'
	}

	@action
	onScrollToEnd = async (filterCondtion, keyword) => {
		const sortBy = this.sortKey && {
			field: this.sortKey,
			order: this.sortKey === 'DISTANCE' ? 'asc' : 'desc',
		}
		const { keywordSearch, nearBySearch, city, ...restFilter } = filterCondtion
		const profession = restFilter?.profession
		const skillNameLength = restFilter?.skillName?.length

		const restValue =
			!!profession && skillNameLength === 0
				? {
						...restFilter,
						skillName: this.disciplineOptions(profession).map((item) => item?.value),
				  }
				: restFilter

		const flatternSaveSearch = omit(restValue, ['name', 'isActive', 'id'])

		const mappingSearch = mappingFilterDate(flatternSaveSearch)
		const response = await apolloClient.query({
			query: SEARCH_SCROLL_END_JOB_QUERY,
			variables: {
				filter: { ...mappingSearch, keyword: this.searchString },
				sortBy,
				limit: PAGE_LIMIT,
				offset: this.jobs?.length || 0,
			},
		})

		logDefaultActionEvent(EVENT_DEFAULT_ID.defaultSearchResult, {
			job_ids: response?.data?.jobSearchV2?.jobs?.map((x) => x.id),
			page_number: this.totalCount === 0 ? 1 : Math.ceil(this.jobs?.length / PAGE_LIMIT) + 1,
			limit: PAGE_LIMIT,
			offset: this.jobs?.length || 0,
			search_param: { filter: this.filterValues, sortBy },
			search_session_id: this.searchSessionId,
		})
		logDefaultActionEvent(EVENT_DEFAULT_ID.loadMoreLanding, {
			jobs: this.jobs?.map((x, index) => {
				return {
					id: x.id,
					avg_weekly: x.weeklyPayAmount,
					order: index,
					percent_matched: x.matchingPercentage,
				}
			}),
			page_number: this.totalCount === 0 ? 1 : Math.ceil(this.jobs?.length / PAGE_LIMIT) + 1,
			limit: PAGE_LIMIT,
			offset: this.jobs?.length || 0,
			list_type: TYPE_JOB.searchResults,
		})
		this.jobs = [...this.jobs, ...response?.data?.jobSearchV2?.jobs]
	}

	@action
	onUpdateSortKey = async (sortKey, filterValues, searchStr) => {
		if (sortKey !== this.sortKey) {
			this.sortKey = sortKey
			this.searchInfo = { totalCount: this.totalCount || 0 }

			await this.fetchSearchResults({ ...filterValues }, searchStr)
			await logClickEvent(EVENT_CLICK_ID.clickSortBtn)
		}
	}

	@action
	onApplyJob = async (jobId) => {
		try {
			const response = await apolloClient.mutate({
				mutation: APPLY_JOB_MUTATION,
				variables: { jobId },
			})

			const jobApplicant = response.data?.createJobApplicant
			this.jobs = this.jobs?.map((job) => (job.id === jobId ? { ...job, jobApplicant } : job))
			notifyStore.success('$MESSAGES.SUCCESSFUL')
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	onApplySuggestedJob = async (jobId) => {
		try {
			const response = await apolloClient.mutate({
				mutation: APPLY_JOB_MUTATION,
				variables: { jobId },
			})

			const jobApplicant = response.data?.createJobApplicant
			this.suggestedJobs = this.suggestedJobs?.map((job) => (job.id === jobId ? { ...job, jobApplicant } : job))
			notifyStore.success('$MESSAGES.SUCCESSFUL')
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	handleSyncSaveSearch = async () => {
		if (authStore.id) {
			//sync save search
			const listSaveSearchAno = JSON.parse(window.localStorage.getItem('saveSearchAno'))
			//javascript change name key from object inside list array
			const listSaveSearchAnoTransfer = listSaveSearchAno
				?.filter((item) => {
					return !item?.savedFilter?.enableAlert
				})
				?.map((item) => {
					const itemRemoveActive = omit(item, ['isActive', 'id', 'savedFilter'])
					const { filterConditions, ...rest } = itemRemoveActive
					const profession = filterConditions?.profession
					const skillNameLength = filterConditions?.skillName?.length
					const restValue =
						!!profession && skillNameLength === 0
							? {
									...filterConditions,
									skillName: this.disciplineOptions(profession).map((item) => item?.value),
							  }
							: filterConditions

					return {
						filterConditionAttributes: omit({ ...restValue, ...rest }, ['savedFilter', 'name', 'isActive', 'id']),
						name: item.name,
					}
				})

			const lastViewJob = lastViewedStore.mappingSyncLastViewJob()
			try {
				await apolloClient.mutate({
					mutation: SYNC_ANONYMOUS_WORKER_DATA,
					variables: {
						savedFiltersAttributes: listSaveSearchAnoTransfer,
						workerViewedJobsAttributes: lastViewJob,
					},
				})
				await window.localStorage.removeItem('saveSearchAno')
				await window.localStorage.removeItem('lastViewJobs')
			} catch (error) {
				await notifyStore.error(error.message)
				await window.localStorage.removeItem('saveSearchAno')
				await window.localStorage.removeItem('lastViewJobs')
			}
		}
	}

	@action
	handleFilterData(dataFilter) {
		const { jobId, radius, ...values } = dataFilter

		const profession = dataFilter.profession
		const skillNameLength = dataFilter.skillName?.length
		if ((!!profession && skillNameLength === 0) || (!!profession && !skillNameLength)) {
			values.skillName = this.disciplineOptions(profession).map((item) => item?.value)
		}

		if (isArray(values?.locationState)) {
			values.locationCities = null
		}

		const filterRadius = omit(values, ['radius'])
		const data = flatten(filterRadius)
		// const normalizeData = omitBy(data, (value) => (typeof value === 'object' ? isEmpty(value) : !value))

		return isArray(values?.locationState)
			? unflatten({
					...data,
					locationCity: null,
			  })
			: unflatten({ ...data })
	}

	@action
	fetchSaveSearhFilter = async () => {
		if (authStore.id) {
			const response = await apolloClient.query({
				query: SAVE_FILTER_QUERY,
			})

			this.listSaveSearch = [...response?.data?.savedFilters].map((item) => {
				if (item.id === this.activeFilterId) {
					return {
						...item,
						isActive: true,
						locationState: item?.filterCondition?.locationState?.split(','),
						alertFrequency: item?.frequency,
					}
				} else {
					return {
						...item,
						isActive: false,
						locationState: item?.filterCondition?.locationState?.split(','),
						alertFrequency: item?.frequency,
					}
				}
			})
		} else {
			if (!isNil(JSON.parse(window.localStorage.getItem('saveSearchAno')))) {
				this.listSaveSearch = JSON.parse(window.localStorage.getItem('saveSearchAno'))
			}
		}
	}

	@action
	handleSaveSearch = async (values) => {
		const deviceId = await window.host.getId()
		const filteredArray = Object.entries(values).filter(([key, value]) => value !== undefined)
		const filteredObject = Object.fromEntries(filteredArray)
		const nameSaved = filteredObject?.name
		const dataSave = omit(filteredObject, ['name', 'isActive'])
		const profession = dataSave?.profession
		const skillNameLength = dataSave?.skillName?.length
		const restValue =
			!!profession && skillNameLength === 0
				? {
						...dataSave,
						skillName: this.disciplineOptions(profession).map((item) => item?.value),
				  }
				: dataSave

		const filterCondtionsLogin = mappingFilterJobSearch(restValue)
		const filterCondtionsAno = mappingFilterJobSearch(dataSave)
		const dataEvent = authStore.id ? { ...filterCondtionsLogin } : { ...filterCondtionsAno }
		const variables = {
			savedFilter: {
				alertChannel: values.alertChannel,
				enableAlert: values.enableAlert,
				frequency: isEmpty(values?.alertFrequency) ? null : values?.alertFrequency,
				name: nameSaved,
				pauseAlert: values.pauseAlert,
				pauseAlertPeriod: values.pauseAlertPeriod,
			},
			filterConditions: filterCondtionsLogin,
		}
		const variablesAnonymous = {
			name: nameSaved,
			id: `uuid_${nanoid()}`,
			savedFilter: {
				name: nameSaved,
				enableAlert: values.enableAlert,
				...(values.enableAlert && {
					alertLastName: values.alertLastName,
					deviceId,
					alertFirstName: values.alertFirstName,
					alertEmail: values.alertEmail,
					alertChannel: authStore.id ? values.alertChannel : 'email',
					frequency: values.alertFrequency,
					pauseAlert: values.pauseAlert,
					pauseAlertPeriod: values.pauseAlertPeriod,
				}),
			},
			filterConditions: mappingFilterJobSearch(dataSave),
			isActive: true,
		}
		logClickEvent(EVENT_CLICK_ID.clickSaveSearchFilterJobsBtn, { ...dataEvent })
		try {
			if (authStore.id) {
				const { data } = await apolloClient.mutate({
					mutation: CREATE_SAVE_MUTATION,
					variables: variables,
				})
				this.listSaveSearch = [...this.listSaveSearch, { ...data?.createSavedFilter }]
				jobAlertsStore.setMode(jobAlertsStore.MODE_LIST.view)
				await notifyStore.success('$MESSAGES.SUCCESSFUL')
			} else {
				this.listSaveSearch =
					this.listSaveSearch?.map((item) => {
						return {
							...item,
							isActive: false,
						}
					}) || []
				const listSaveSearchAno =
					!isNil(window.localStorage.getItem('saveSearchAno')) && !isEmpty(window.localStorage.getItem('saveSearchAno'))
						? JSON.parse(window.localStorage.getItem('saveSearchAno'))
						: []
				const listSaveSearchAnoTransfer =
					listSaveSearchAno?.map((item) => {
						return {
							...item,
							isActive: false,
						}
					}) || []
				let listSaveAnonymous = []
				if (values.enableAlert === true) {
					const { data } = await apolloClient.mutate({
						mutation: CREATE_SAVE_MUTATION,
						variables: variablesAnonymous,
					})
					const createSavedFilter = data?.createSavedFilter
					const filterCondition = createSavedFilter?.filterCondition
					const variableTracing = {
						name: createSavedFilter?.name,
						id: createSavedFilter?.id,
						savedFilter: {
							name: nameSaved,
							enableAlert: createSavedFilter.enableAlert,
							...(createSavedFilter?.enableAlert && {
								alertChannel: createSavedFilter?.alertChannel,
								frequency: createSavedFilter?.alertFrequency,
								pauseAlert: createSavedFilter?.pauseAlert,
								pauseAlertPeriod: createSavedFilter?.pauseAlertPeriod,
								alertLastName: createSavedFilter?.alertLastName,
								deviceId,
								alertFirstName: createSavedFilter?.alertFirstName,
								alertEmail: createSavedFilter?.alertEmail,
							}),
						},
						filterConditions: filterCondition,
						isActive: true,
					}
					listSaveAnonymous = [...listSaveSearchAnoTransfer, variableTracing]
				} else {
					listSaveAnonymous = [...listSaveSearchAnoTransfer, variablesAnonymous]
				}
				const lastSearchAno = variablesAnonymous
				this.listSaveSearch = listSaveAnonymous
				window.localStorage.setItem('saveSearchAno', JSON.stringify(listSaveAnonymous))
				window.localStorage.setItem('lastSearch', JSON.stringify(lastSearchAno))
				jobAlertsStore.setMode(jobAlertsStore.MODE_LIST.view)
				await notifyStore.success('$MESSAGES.SUCCESSFUL')
			}
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	handleUpdateSearch = async (values) => {
		const deviceId = await window.host.getId()
		const nameSaved = values?.name
		const dataSave = omit(values, ['name'])
		const id = values?.id
		const variables = {
			savedFilter: {
				id: id,
				name: nameSaved,
				enableAlert: values.enableAlert,
				...(values?.enableAlert && {
					pauseAlert: values.pauseAlert,
					pauseAlertPeriod: values.pauseAlertPeriod,
					alertChannel: authStore.id ? values.alertChannel : 'email',
					frequency: values.alertFrequency,
					alertLastName: values.alertLastName,
					deviceId,
					alertFirstName: values.alertFirstName,
					alertEmail: values.alertEmail,
				}),
			},
			filterConditions: omit(convertStateToString(mappingFilterJobSearch(this.handleFilterData(dataSave))), ['radius', 'isActive']),
		}

		if (authStore.id) {
			try {
				const { data } = await apolloClient.mutate({
					mutation: UPDATE_SAVE_MUTATION,
					variables: variables,
				})

				this.listSaveSearch = this.listSaveSearch?.map((item) =>
					item.id === data?.updateSavedFilter?.id
						? { ...data?.updateSavedFilter, isActive: true }
						: {
								...item,
								isActive: false,
						  }
				)

				jobAlertsStore.setMode(jobAlertsStore.MODE_LIST.view)

				await notifyStore.success('$MESSAGES.SUCCESSFUL')

				// await this.fetchSaveSearhFilter()
			} catch (error) {
				notifyStore.error(error.message)
			}
		} else {
			const dataSave = values
			const id = values?.id
			const name = values?.name
			const variblesAnonymous = {
				name,
				id,
				filterConditions: mappingFilterJobSearch(this.handleFilterData(dataSave)),
				isActive: true,
			}
			let listSaveAnonymous = []
			if (values.enableAlert === true) {
				try {
					if (id.includes('uuid_')) {
						const { data } = await apolloClient.mutate({
							mutation: CREATE_SAVE_MUTATION,
							variables: variables,
						})
						const createSavedFilter = data?.createSavedFilter
						const filterCondition = createSavedFilter?.filterCondition
						const variableTracing = {
							name: createSavedFilter?.name,
							id: createSavedFilter?.id,
							savedFilter: {
								enableAlert: createSavedFilter?.enableAlert,
								...(createSavedFilter?.enableAlert && {
									alertLastName: createSavedFilter?.alertLastName,
									deviceId,
									alertFirstName: createSavedFilter?.alertFirstName,
									alertEmail: createSavedFilter?.alertEmail,
									alertChannel: authStore.id ? createSavedFilter?.alertChannel : 'email',

									frequency: createSavedFilter?.frequency,
									name: nameSaved,
									pauseAlert: createSavedFilter?.pauseAlert,
									pauseAlertPeriod: createSavedFilter?.pauseAlertPeriod,
								}),
							},
							filterConditions: filterCondition,
							isActive: true,
						}
						listSaveAnonymous = isArray(this.listSaveSearch) && this.listSaveSearch?.map((item) => (item.id === id ? variableTracing : item))
						this.listSaveSearch = listSaveAnonymous
					} else {
						const { data } = await apolloClient.mutate({
							mutation: UPDATE_SAVE_MUTATION,
							variables: variables,
						})
						const updateSavedFilter = data?.updateSavedFilter
						const filterCondition = updateSavedFilter?.filterCondition
						const variableTracing = {
							name: updateSavedFilter?.name,
							id: updateSavedFilter?.id,
							savedFilter: {
								enableAlert: updateSavedFilter?.enableAlert,
								...(updateSavedFilter?.enableAlert && {
									alertLastName: updateSavedFilter?.alertLastName,
									deviceId,
									alertFirstName: updateSavedFilter?.alertFirstName,
									alertEmail: updateSavedFilter?.alertEmail,
									alertChannel: authStore.id ? updateSavedFilter?.alertChannel : 'email',
									frequency: updateSavedFilter?.alertFrequency,
									name: nameSaved,
									pauseAlert: updateSavedFilter?.pauseAlert,
									pauseAlertPeriod: updateSavedFilter?.pauseAlertPeriod,
								}),
							},
							filterConditions: filterCondition,
							isActive: true,
						}
						listSaveAnonymous = isArray(this.listSaveSearch) && this.listSaveSearch?.map((item) => (item.id === id ? variableTracing : item))
						this.listSaveSearch = listSaveAnonymous
					}
					window.localStorage.setItem('lastSearch', JSON.stringify(variblesAnonymous))
					window.localStorage.setItem('saveSearchAno', JSON.stringify(listSaveAnonymous))
					jobAlertsStore.setMode(jobAlertsStore.MODE_LIST.view)
					await notifyStore.success('$MESSAGES.SUCCESSFUL')
				} catch (error) {
					notifyStore.error(error.message)
				}
			} else {
				listSaveAnonymous =
					isArray(this.listSaveSearch) &&
					this.listSaveSearch?.map((item) => {
						const id = item?.id
						if (id.includes('uuid_')) {
							return item?.id === id ? { ...variblesAnonymous, id: `uuid_${nanoid()}` } : item
						} else {
							return item?.id === id ? { ...variblesAnonymous, id: `uuid_${nanoid()}` } : item
						}
					})
				this.listSaveSearch = listSaveAnonymous

				window.localStorage.setItem('lastSearch', JSON.stringify(variblesAnonymous))
				window.localStorage.setItem('saveSearchAno', JSON.stringify(listSaveAnonymous))
				jobAlertsStore.setMode(jobAlertsStore.MODE_LIST.view)
				await notifyStore.success('$MESSAGES.SUCCESSFUL')
			}
		}
	}

	@action
	setActiveItemFilter = (id, setValues) => {
		this.listSaveSearch = this.listSaveSearch?.map((item) => {
			if (item.id === id) {
				return {
					...item,
					isActive: true,
				}
			} else {
				return {
					...item,
					isActive: false,
				}
			}
		})
		this.setActiveFilterId(id)
		setValues(this.getActiveItem)
		if (!authStore.id) {
			window.localStorage.setItem('saveSearchAno', JSON.stringify(this.listSaveSearch))
		}
	}

	@action
	setIsShowSaveSearchDialog = (value) => {
		this.isShowSaveSearchDialog = value
	}

	@action
	fetchSearchResults = async (filterCondition) => {
		const sortBy = this.sortKey && {
			field: this.sortKey,
			order: this.sortKey === 'DISTANCE' ? 'asc' : 'desc',
		}

		const { keywordSearch, nearBySearch, city, ...restFilter } = filterCondition

		const profession = restFilter?.profession
		const skillNameLength = restFilter?.skillName?.length

		const restValue =
			(!!profession && skillNameLength === 0) || (!!profession && isNil(skillNameLength))
				? {
						...restFilter,
						skillName: this.disciplineOptions(profession).map((item) => item?.value),
				  }
				: restFilter

		const flatternSaveSearch = convertStateToString(omit(restValue, ['name', 'isActive', 'id']))

		const mappingSearch = mappingFilterDate(flatternSaveSearch)

		const response = await apolloClient.query({
			query: SEARCH_JOB_QUERY,
			variables: {
				companyId: appStore.id,
				filter: { ...mappingSearch },
				keywordSearch,
				nearBySearch,
				sortBy,
				token: this.token,
				limit: PAGE_LIMIT,
				offset: 0,
			},
		})

		this.searchInfo = response?.data?.jobSearchV2

		this.jobs = response?.data?.jobSearchV2?.jobs
		this.totalCount = response?.data?.jobSearchV2?.totalCount
		if (!authStore.id) {
			careHomeStore.totalCountLastSearch = response?.data?.jobSearchV2?.totalCount
		}
		logDefaultActionEvent(EVENT_DEFAULT_ID.loadInitialLanding, {
			jobs: this.jobs?.map((x, index) => {
				return {
					id: x.id,
					avg_weekly: x.weeklyPayAmount,
					order: index,
					percent_matched: x.matchingPercentage,
				}
			}),
			page_number: this.totalCount === 0 ? 1 : Math.ceil(this.jobs?.length / PAGE_LIMIT) + 1,
			limit: PAGE_LIMIT,
			list_type: TYPE_JOB.searchResults,
		})
	}
	@action
	fetchApplicant = async (id) => {
		const { data } = await apolloClient.query({ query: GET_JOB_DETAIL_QUERY, variables: { id } })
		this.jobApplicantId = data?.workerAssignment?.job?.jobApplicant?.id
	}

	@event(EVENTS.authStore.logout)
	userLogout() {
		this.searchData = null
		this.sortKey = 'SCORE'
	}
}

export const careFindJobStore = new CareFindJobStore()
