export const PHONE_FORMAT = '(###) ###-####'
export const PAY_FREQUENCY = [
	{
		label: 'Weekly',
		value: 'weekly',
	},
	{
		label: 'Yearly',
		value: 'yearly',
	},
]

export const PAY_FREQUENCY_SHORT = {
	weekly: 'wk',
	yearly: 'yr',
}

export const JNP_META_DATA = {
	'/search': {
		title: 'Search Nurse Jobs',
		description: 'Search nursing jobs by location, pay, and specialty. Travel nursing with JNP is easy, because it’s what you deserve.',
	},
	'/authorization': {
		title: 'Authorization Nurse Professionals – JNP Pulse',
		description:
			"Apply to Jobs. Track Referral Income. Message Your Recruiter. Meet Other Travelers. JNP Pulse puts you in the driver's seat of your career by delivering travel…",
	},
	'/signin': {
		title: 'Login Nurse Professionals – JNP Pulse',
		description:
			"Apply to Jobs. Track Referral Income. Message Your Recruiter. Meet Other Travelers. JNP Pulse puts you in the driver's seat of your career by delivering travel…",
	},
	'/': {
		title: 'Jackson Nurse Professionals – Travel Nurse Staffing Agency',
		description:
			'Travel nursing is financially rewarding, but aspects of it can be a hassle and we’re here to take care of all the details. We’ll seek to understand your career goals and preferences. We’ll work to find the assignments that align and provide you with options. We’ll keep you informed, and we’ll have your back.',
	},
}

export const JTP_META_DATA = {
	'/search': {
		title: 'Search Healthcare Jobs - ProVenture',
		description: "We put you in the driver's seat of your career. Search therapy, allied, and education jobs nationwide.",
	},
	'/authorization': {
		title: 'ProVenture Login - ProVenture',
		description: 'Talent login for therapy, allied, education, and healthcare professionals with JTP.',
	},
	'/signin': {
		title: 'ProVenture Login - ProVenture',
		description: 'Talent login for therapy, allied, education, and healthcare professionals with JTP.',
	},
	'/reset': {
		title: 'ProVenture Reset - ProVenture',
		description: 'Reset your password to ProVenture simply by entering your email address.',
	},
}

export const JTP_META_DEFAULT = {
	title: process.env.REACT_APP_COMPANY_NAME,
	description: "We put you in the driver's seat of your career. Search therapy, allied, and education jobs nationwide.",
}

export const JNP_META_DEFAULT = {
	title: process.env.REACT_APP_COMPANY_NAME,
	description:
		'Travel nursing is financially rewarding, but aspects of it can be a hassle and we’re here to take care of all the details. We’ll seek to understand your career goals and preferences. We’ll work to find the assignments that align and provide you with options. We’ll keep you informed, and we’ll have your back.',
}

export const PAGE_LIMIT = 24
